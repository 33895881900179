@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$primary-color: #137c40;
$secondary-color: #f1f7f4;
$primary-card-bg-color: #fdfdfd;
$primary-text-color: #1c1c1c;
$bg-color: #ececec;
$form-component-outline-color: #bfbfbf;
$divider-color: #d7d7d7;
$cards-outline-color: #f3f3f3;

$font-family: "Montserrat", sans-serif;
$black: #171717;



//Temporários
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-24: 24px;
$font-weight-bold: bold;

$padding-size: 15px;

$alert-color: #e54444;
$research-btn-color: #007bff;
$clear-research-btn-color: #6c757d;
$white-color: #fff;

$box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
$border-radius: 10px 10px 10px 10px;

$card-header-with-select-padding: 0.5rem;
$slide-out-shadow-color: 0 4px 14px 0;

@mixin ng-card-borderless {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: none;
}
