@import 'src/app/@theme/styles/design_system.scss';

$header-height: 40px !important;
$header-cell-padding: .75rem 1rem !important;
$row-height: 38px !important;
$row-cell-padding: .375rem 1rem !important;
$table-font-size: 1rem !important;

@mixin column-divider {
  content: '';
  position: absolute;
  right: 0;
  height: 60%;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  background-color: $divider-color;
}

.ngx-datatable.spaced {
  color: $primary-text-color;
  border-radius: 5px;
  background-color: transparent;
  overflow-y: auto;

  .datatable-header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 11;
    height: $header-height;
    border-radius: 5px;
    margin-bottom: 5px;
    background-color: $primary-card-bg-color;

    .datatable-header-cell {
      height: $header-height;
      padding: $header-cell-padding;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: bold;

      // Separator between header columns
      &:not(:last-of-type)::after {
        @include column-divider;
      }

      // Align header content and sort button
      .datatable-header-cell-template-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .sort-btn {
          padding-left: .3rem;
        }
      }
    }
  }

  // Force table to take full container's width
  .datatable-scroll {
    width: 100% !important;
  }

  .datatable-row-wrapper {
    margin-bottom: 5px;
    border-radius: 5px;

    // Set row detail (collapse) core styles
    .datatable-row-detail {
      padding: $row-cell-padding;
      background-color: $primary-card-bg-color;
      border-radius: 0 0 5px 5px;
    }

    // Update row border radius when detail is visible (collapse)
    datatable-body-row[ng-reflect-expanded=true] {
      border-radius: 5px 5px 0 0 !important;
    }

    .datatable-body-row {
      height: $row-height;
      background-color: $primary-card-bg-color;
      border-radius: 5px;

      &:hover {
        background-color: $cards-outline-color !important;
      }

      .datatable-body-cell {
        height: $row-height;
        padding: $row-cell-padding;
        position: relative;

        // Separator between row columns
        &:not(:last-of-type)::after {
          @include column-divider;
        }
      }
    }

    // set style for expand mat-button (row detail)
    .expand-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      height: 25px !important;
      width: 25px !important;

      &:focus {
        outline: none;
      }

      .mat-icon {
        margin-top: 2px;
        font-size: 24px !important;
      }

      .mat-mdc-button-touch-target {
        width: 25px !important;
        height: 25px !important;
      }
    }

    // set style for actions mat-fab-button
    button[mat-mini-fab] {
      height: 25px;
      width: 25px;
      min-width: 25px;
      box-shadow: none;

      .mat-mdc-button-touch-target {
        height: 25px;
        width: 25px;
      }

      .mat-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .material-icons {
        font-size: 16px;
      }
    }

    // set style for actions mat-icon-button
    button[mat-icon-button] {
      width: 35px;
      height: 35px;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      & > *[role=img] {
        width: 18px;
        height: 18px;
        font-size: 18px;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .mat-mdc-button-touch-target {
        width: 35px;
        height: 35px;
      }
    }
  }

  .datatable-footer {
    font-size: 12px;
    border-radius: 5px;

    .datatable-footer-inner {
      background-color: $primary-card-bg-color;
      padding: $header-cell-padding;

      .pager {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;

        li {
          font-size: 14px;
          height: 25px;
          width: 25px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 2.5px;

          &:hover {
            background-color: $cards-outline-color;

            a {
              color: $primary-text-color;
            }
          }

          // hide buttons "jump to first" and "jump to last"
          &:first-of-type,
          &:last-of-type {
            overflow: hidden;
            display: none;
          }

          a {
            color: $form-component-outline-color;
            text-decoration: none;
            height: 100%;
            width: 100%;
            text-align: center;

            .datatable-icon-left,
            .datatable-icon-right {
              text-decoration: none;
              color: $primary-text-color;
              font-size: 24px;
            }
          }
        }

        li.active {
          font-weight: bold;
          background-color: $cards-outline-color;
          a {
            color: $primary-text-color;
          }
        }

        li.disabled a {
          opacity: .25 !important;
          cursor: default !important;
        }

        li.pages a {
          line-height: 1.75;
        }
      }
    }
  }

  .empty-row {
    background-color: $primary-card-bg-color;
    border-radius: 5px;
    height: $row-height;
    padding: $row-cell-padding;
    font-size: $table-font-size;
    margin-bottom: 5px;
  }
}
