@import './themes';
@import './design_system';

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }
}


*:not(mat-icon){
  font-family: $font-family !important;
  letter-spacing: 0;
}

.btn-primary {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
}
