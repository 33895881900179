@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'material-design-icons/iconfont/material-icons.css';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// custom ngx-datatables
@import 'src/app/@theme/styles/tables/datatable-standard.scss';
@import 'src/app/@theme/styles/tables/datatable-spaced.scss';

// install the framework and custom global styles
@include nb-install() {
   //framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}
