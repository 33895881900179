@import './design_system.scss';

@mixin ngx-layout() {
  @include media-breakpoint-down(is) {
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

.app-box {
  width: 100%;
}

.custom-icon-button {
  transform: scale(0.8);
  padding: 0px;
  height: 32px;
}

.custom-icon {
  font-size: $font-size-24;
}

.custom-action-column {
  margin-left: -18px;
}

.full-width-field {
  width: 100%;
}

.ngx-datatable.fixed-row
  .datatable-scroll
  .datatable-body-row
  .datatable-body-cell,
.ngx-datatable.fixed-row
  .datatable-scroll
  .datatable-body-row
  .datatable-body-group-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 0px !important;
  font-size: 15px !important;
  display: flex !important;
  align-items: center !important;
}

.ngx-datatable.material.striped .datatable-row-odd {
  background: rgba(11, 125, 62, 0.0588235294) !important;
}

.ngx-datatable .datatable-body-row:hover {
  background-color: #d1e9ff !important; /* Cor de fundo azul claro ao passar o mouse */
}

.ngx-datatable.material .datatable-body .datatable-row-detail {
  background: $white-color !important;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  color: black !important;
  background-color: $white-color;
  vertical-align: bottom;
  font-size: $font-size-14 !important;
  font-weight: $font-weight-bold !important;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: $border-radius;
  border-radius: $border-radius;
  background: $white-color;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
  text-align: center;
}

.text-black {
  font-weight: $font-weight-bold;
}

.btn.btn-outline-success {
  color: $white-color;
  border-color: $primary-color;
  background-color: $primary-color;
  transition: 0.5s;

  &:hover {
    background-color: $primary-color;
  }
}

.btn.btn-outline-primary {
  border-color: $research-btn-color;
  background-color: $research-btn-color;
  color: $white-color;

  &:hover {
    background-color: $research-btn-color;
  }
}

.btn.btn-info {
  background-color: $clear-research-btn-color;
  border-color: $clear-research-btn-color;
  color: $white-color;

  &:hover {
    background-color: $clear-research-btn-color;
  }
}

nb-layout {
  &.layout {
    background-color: #0b7d3e0f;
  }
}

.nb-icon-back {
  cursor: pointer;
}

.ng-card-borderless-bottom {
  @include ng-card-borderless;
  border-bottom: none;
}

.ng-card-borderless-top {
  @include ng-card-borderless;
  border-top: none;
}

.bold {
  font-weight: $font-weight-bold;
}

.iti {
  width: 100%;
}

.cdk-overlay-container {
  z-index: 1040 !important;
}


// Overwrite Angular Material Checkbox Color
// unchecked
.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: $primary-color !important;
  opacity: 80%;
}

// waiting for check - indeterminate
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

// checked
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
}
